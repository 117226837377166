:root {
  --primary-color: #7cc0c1;
  --secondary-color: #56bcbf;
  --blue-light: #ecfcff;
  --grey: #b9b9b9;
}

.ant-form-item-explain-error {
  font-size: 13px;
}

.ant-modal {
  width: 900px;
}

.ant-modal-body {
  padding: 50px;
}

.ant-modal-footer {
  border-top: none;
  flex-direction: row;
  display: flex;
  justify-content: flex-end;
}

.ant-picker {
  background-color: white;
  border: none;
  border-radius: 4px;
  height: 35px;
  border: 1px solid var(--grey);
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: 35px;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.ant-select-selection-search-input {
  height: 100% !important;
}

/* MENU */

.ant-menu-overflow.ant-menu.ant-menu-root.ant-menu-horizontal.ant-menu-light {
  height: 65px;
  padding-left: 14vw;
  padding-right: 14vw;
  display: flex;
  align-items: center;
  box-shadow: 1px 0 3px #e2e2e2;
  background-color: var(--primary-color);
}

.ant-menu-horizontal > .ant-menu-item-selected a {
  color: white !important;
  font-weight: 600;
}

.ant-menu-horizontal > .ant-menu-item a {
  color: white !important;
}

.ant-menu-title-content {
  color: white;
}

.ant-menu-item-selected::after {
  border-bottom: 2px solid white !important;
}

.ant-menu-submenu-selected::after {
  border-bottom: 2px solid white !important;
}

.ant-menu-submenu-title {
  height: 65px;
  display: flex;
  align-items: center;
  font-size: 16px;
}

.ant-menu-overflow-item.ant-menu-item.ant-menu-item-only-child {
  height: 65px;
  display: flex;
  align-items: center;
  font-size: 16px;
}

.header .ant-menu-title-content {
  font-weight: 400;
  font-size: 16px;
}

.ant-upload-picture-card-wrapper {
  width: auto;
}

.ant-upload-list-picture-card {
  display: flex;
  justify-content: center;
  width: auto;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper {
  width: auto;
}

.ant-anchor-wrapper {
  background: var(--blue-light);
  border: 1px solid rgb(222, 222, 222);
  border-radius: 6px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  max-height: fit-content !important;
  width: 100%;
}

.ant-anchor-link-title {
  margin-top: 5px;
}
.ant-anchor-link-title:hover {
  text-decoration: underline;
}

.ant-dropdown-trigger {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

/* MENU */

@media (max-width: 1150px) {
  .ant-modal {
    width: 95vw;
  }

  .ant-modal-body {
    padding: 50px 20px;
  }
  .ant-table {
    padding: 0 !important;
  }
}
.center .ant-form-item-control-input-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-form-item {
  margin-bottom: 18px;
}

.ant-form-item-explain-error {
  font-size: 13px;
}

.ant-drawer-body {
  padding: 0;
}

.anticon.anticon-right.ant-collapse-arrow {
  left: auto;
  right: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
}

.ant-table {
  padding: 30px;
}

.ant-table-container {
  border: none !important;
}

.ant-table-tbody > tr > td {
  border: none;
  border-bottom: 1px solid var(--grey);
}

.ant-table-thead > tr > th {
  background: none;
}

.ant-modal-body {
  padding: 50px 20px;
}

.anticon.anticon-right.ant-collapse-arrow {
  left: auto;
  right: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
}

.ant-table {
  padding: 30px;
}

.ant-table-container {
  border: none !important;
}

.ant-table-tbody > tr > td {
  border: none;
  border-bottom: 1px solid var(--grey);
}

.ant-table-thead > tr > th {
  background: none;
}

.ant-table-cell {
  border: none !important;
  border-bottom: 1px solid var(--grey) !important;
}

.ant-table-thead .ant-table-cell {
  font-weight: bold;
  font-size: 13px;
}

.ant-table-content > table {
  border: none !important;
}

.ant-table-pagination-right {
  justify-content: center;
}


.blue-back .ant-drawer-body {
  background-color: white;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.ant-drawer-close {
  font-size: 20px;
}

.ant-input-number-affix-wrapper {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.ant-input-number-prefix {
  color: var(--grey);
}
